export default URL = "https://gorakhpurhomecare.com/admin";

export const constants = {
    phone: "+916391000414",
    email: "contact@gorakhpurhomecare.com"
}

export const SeoData = {
    title: "Online cake delivery in Gorakhpur",
    decerption: "gorakhpurhomecare.com offers online cake delivery in Gorakhpur. Now  order cake online in Gorakhpur from the best birthday/anniversary cakes for same day cake delivery.",
    keyword:'gorakhpurhomecare.com , Online Shopping in Gorakhpur ,Online Cake In Gorakhpur, birthday cake, anniversary cake Store, Grocery  , Gorakhpur , Grocery home Delivery service , Best Online Shopping in Gorakhpur , online grocery in gorakhpur , online shopping gorakhpur , Shopping In Gorakhpur , gorakhpurhomecare.com Gorakhpur , gorakhpurhomecare.com India, Shopping Website In Gorakhpur , Online Shopping In Gorakhpur , Shopping Site In Gorakhpur, Biggest Shopping in Gorakhpur,Online Shopping in India, free delivery shopping in  gorakhpur , Online Kirana Grocery shop in Gorakhpur , Online kirana store gorakhpur , Home Delivery in gorakhpur , Gorakhpur online portal , grocery delivery in gorakhpur , gorakhpurhomecare.com Grocery ,  gorakhpurhomecare.com Personal Care , gorakhpurhomecare.com Baby Care , gorakhpurhomecare.com Cleaning & Householding , gorakhpurhomecare.com Beverages',
    author:'gorakhpurhomecare.com'
}
